<template>
  <div>
    <validation-observer ref="surveyRules">
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card no-body>
              <b-card-header>
                <h4 class="card-title">{{i18nT(`Survey`)}}</h4>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="onSave()">
                    <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="hasRouteId"
                    @click="onDelete()"
                  >
                    <span
                      class="align-middle ml-50"
                      style="color: red"
                    >{{i18nT(`Delete`)}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-card-body>
                <b-row class="border-bottom pb-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5 class="mt-2">{{i18nT(`Survey details`)}}</h5>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          :label="i18nT(`Title`)" class="required"
                          label-for="surveyTitle"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Title`)"
                            rules="required"
                          >
                            <b-form-input
                              id="surveyTitle"
                              v-model="surveyTitle"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-for="template"
                        >
                          <b-form-checkbox
                            name="check-button"
                            switch
                            :checked="surveyIsTemplate"
                            v-model="surveyIsTemplate"
                          >
                          {{i18nT(`Save as template`)}}
                          </b-form-checkbox>
                        </b-form-group>

                      <b-alert
                        class="mt-1"
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                          <span>
                            <b-badge variant="primary">
                              {{i18nT(`Info`)}}
                            </b-badge>
                            {{i18nT(`You can save common surveys as templates for later use. You will find them in Survey templates.`)}}
                          </span>
                        </div>
                      </b-alert>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="i18nT(`Type`)"
                          label-for="surveyType"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Type`)"
                            rules="required"
                          >
                            <v-select
                              v-model="surveyType"
                              :options="surveyTypes"
                              label="text"
                              id="surveyType"
                              :state="errors.length > 0 ? false:null"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="i18nT(`Audience`)"
                          label-for="audienceGroup"
                        >
                          <v-select
                            v-model="audienceGroup"
                            :options="audienceGroups"
                            label="text"
                            id="audienceGroup"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="surveyDescription"
                        >
                          <quill-editor
                              id="Description"
                              v-model="surveyDescription"
                              :options="quillOption"
                              :row="10"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="border-bottom py-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5 class="mt-2">{{i18nT(`Questions`)}}</h5>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                    class="pt-2"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <h6 class="mb-1">{{i18nT(`Questions list`)}}</h6>
                        <p>{{i18nT(`Add and organize the questions of your survey.`)}}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <draggable
                          v-model="surveyQuestions"
                          tag="ul"
                          class="list-group cursor-move"
                          @end="updateAnswerOrder"
                        >
                          <b-list-group-item
                            v-for="(surveyQuestion, index) in surveyQuestions"
                            :key="index"
                            tag="li"
                          >
                            <b-button-group
                              class="position-absolute"
                              style="right: 10px"
                            >
                              <b-button
                                variant="outline-primary"
                                @click.prevent="editSurveyQuestionByIdx(index)"
                              >
                                <feather-icon icon="EditIcon" />
                              </b-button>
                              <b-button
                                variant="outline-danger"
                                @click.prevent="removeSurveyQuestionByIdx(index)"
                              >
                                <feather-icon
                                  icon="TrashIcon"
                                />
                              </b-button>
                            </b-button-group>
                            <h5>{{ surveyQuestion.Label }}</h5>
                            <p>{{ surveyQuestion.Description }}</p>
                            <ul v-if="surveyQuestion.Type === 1">
                              <li>
                                <strong>{{
                                  getNameByQuestionTypeId(surveyQuestion.Type)
                                }}</strong>
                              </li>
                              <li>
                                {{
                                  surveyQuestion.LinesCnt === 99
                                    ? "Multi-line"
                                    : "Single-line"
                                }}
                              </li>
                            </ul>
                            <ul v-else-if="surveyQuestion.Type === 2">
                              <li>
                                <strong>{{
                                  getNameByQuestionTypeId(surveyQuestion.Type)
                                }}</strong>
                              </li>
                              <li
                                v-for="answer in surveyQuestion.AnswersData"
                                :key="answer.Id"
                              >
                                {{ answer.Label }}
                              </li>
                            </ul>
                            <ul v-else-if="surveyQuestion.Type === 3">
                              <li>
                                <strong>{{
                                  getNameByQuestionTypeId(surveyQuestion.Type)
                                }}</strong>
                              </li>
                              <li>
                                {{
                                  getNameByQuestionGradingId(
                                    surveyQuestion.Grading
                                  )
                                }}
                              </li>
                            </ul>
                          </b-list-group-item>
                        </draggable>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          class="mt-0 mt-md-2"
                          v-b-modal.modal-question
                        >
                          <feather-icon icon="PlusCircleIcon" />
                          {{i18nT(`Add new`)}}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="border-bottom py-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5 class="mt-2">{{i18nT(`Scheduling`)}}</h5>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                    class="pt-2"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <p>
                          {{ i18nT(`Schedule the survey and send it once or set it as a recurring survey.`) }}
                        </p>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label-for="schedule"
                        >
                          <b-form-checkbox
                          name="check-button"
                          switch
                          :checked="surveyScheduling"
                          v-model="surveyScheduling"
                            >
                                {{i18nT(`Repeat survey`)}}
                            </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row v-if="surveyScheduling">
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="i18nT(`Frequency`)"
                          label-for="surveyScheduleFor"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Frequency`)"
                            rules="required"
                          >
                            <v-select
                              v-model="surveyRepeatFor"
                              :options="repeatOptions"
                              label="text"
                              id="surveyScheduleFor"
                              :clearable="false"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="surveyRepeatFor && surveyRepeatFor.value === '1'"
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="i18nT(`Start Date`)"
                          label-for="surveyScheduleDate"
                        >
                          <date-picker
                            reset-button
                            id="surveyScheduleDate"
                            v-model="surveyScheduleDate"
                            class="mb-2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="surveyRepeatFor && surveyRepeatFor.value === '2'"
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="i18nT(`Weekly Schedule`)"
                          label-for="surveyScheduleWeekly"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Weekly Schedule`)"
                            rules="required"
                          >
                            <v-select
                              v-model="surveyScheduleWeekly"
                              :options="scheduleWeeklyOptions"
                              multiple
                              :clearable="false"
                              label="text"
                              id="surveyScheduleWeekly"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="surveyRepeatFor && surveyRepeatFor.value === '3'"
                        cols="12"
                        md="2"
                      >
                        <b-form-group
                          :label="i18nT(`Day`)"
                          label-for="surveyScheduleDayOfMonth"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Day`)"
                            rules="required"
                          >
                            <v-select
                              v-model="surveyScheduleDayOfMonth"
                              :options="scheduleDaysOfMonthOptions"
                              label="text"
                              :clearable="false"
                              id="surveyScheduleDayOfMonth"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="surveyRepeatFor && surveyRepeatFor.value === '3'"
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="i18nT(`Monthly Schedule`)"
                          label-for="surveyScheduleMonthly"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Monthly Schedule`)"
                            rules="required"
                          >
                            <v-select
                              v-model="surveyScheduleMonthly"
                              :options="scheduleMonthlyOptions"
                              multiple
                              :clearable="false"
                              label="text"
                              id="surveyScheduleMonthly"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="surveyRepeatFor && surveyRepeatFor.value === '4'"
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="i18nT(`Due Date`)"
                          label-for="surveyScheduleDate"
                        >
                          <date-picker
                            reset-button
                            id="surveyScheduleDate"
                            v-model="surveyScheduleDate"
                            class="mb-2"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <div style="flex: 1" />
                    <b-button
                        v-if="hasRouteId == 'new' || notSent"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        class="mt-0 mt-md-2 ml-1 mr-1"
                        @click.prevent="onAddNewSurveySubmit(true)"
                    >
                        {{ i18nT(`Save & Send`) }}
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-0 mt-md-2 ml-1 mr-1"
                        @click.prevent="onAddNewSurveySubmit(false)"
                    >
                        {{ i18nT(`Save`) }}
                    </b-button>

                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- MODAL -->
    <b-modal
      id="modal-question"
      :title="i18nT(`Add new`)"
      :ok-title="i18nT(`Save`)"
      ok-only
      prevent-closing
      @ok="onAddNewQuestionSubmit"
      @hidden="clearQuestionData"
      no-close-on-backdrop
      size="lg"
    >
      <b-tabs
        pills
        align="center"
        v-model="tabIndex"
      >
          <b-tab>
              <template #title>
                  <feather-icon icon="UploadCloudIcon" /> {{i18nT(`New question`)}}
              </template>
              <validation-observer ref="newQuestion">
                  <b-form class="mt-2">
                      <b-row>
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Type`)"
                                  label-for="questionType"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      name="Type"
                                      rules="required"
                                  >
                                      <v-select
                                          v-model="questionType"
                                          :options="questionTypes"
                                          label="text"
                                          :clearable="false"
                                          :state="errors.length > 0 ? false:null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Your question`)" class="required"
                                  label-for="questionLabel"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      name="title"
                                      rules="required"
                                  >
                                      <b-form-input
                                          v-model="questionLabel"
                                          :placeholder="i18nT(`E.g. Question Name`)"
                                          name="questionLabel"
                                          id="questionLabel"
                                          :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Description`)"
                                  label-for="questionDescription"
                              >
                                  <b-form-textarea
                                      id="questionDescription"
                                      rows="4"
                                      v-model="questionDescription"
                                  />
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <b-row v-if="questionType.value === '1'">
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Free text answer filed`)"
                                  label-for="freeText"
                              >
                                  <div class="demo-inline-spacing">
                                      <b-form-radio
                                          v-model="questionLinesCnt"
                                          name="questionLinesCnt"
                                          value="1"
                                          class="mt-0"
                                      >
                                          {{i18nT(`Single line`)}}
                                      </b-form-radio>
                                      <b-form-radio
                                          v-model="questionLinesCnt"
                                          name="questionLinesCnt"
                                          value="99"
                                          class="mt-0"
                                      >
                                          {{i18nT(`Multi-line`)}}
                                      </b-form-radio>
                                  </div>
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <b-row v-if="questionType.value === '2'">
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Multiple choice answers`)" class="required"
                                  label-for="freeText"
                              >
                                  <draggable
                                      v-model="questionAnswersData"
                                      tag="ul"
                                      class="list-group cursor-move"
                                      @end="updateAnswerOrder"
                                  >
                                      <b-list-group-item
                                          v-for="(answer, index) in questionAnswersData"
                                          :key="index"
                                          tag="li"
                                      >
                                          <validation-provider
                                              #default="{ errors }"
                                              name="answer"
                                              rules="required"
                                          >
                                              <div class="draggable-container d-flex">
                                                  <b-form-input
                                                      v-model="answer.text"
                                                      :placeholder="i18nT(`Possible answer`)"
                                                      name="questionAnswersData"
                                                      :state="errors.length > 0 ? false : null"
                                                  />
                                                  <div class="d-flex align-items-center">
                                                      <feather-icon
                                                          icon="Trash2Icon"
                                                          size="17"
                                                          class="cursor-pointer ml-1"
                                                          @click="onDeleteAnswerClick(index)"
                                                      />
                                                  </div>
                                              </div>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                      </b-list-group-item>
                                  </draggable>
                                  <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      variant="outline-primary"
                                      class="mt-1"
                                      @click.prevent="onAddNewAnswerClick"
                                  >
                                      <feather-icon icon="PlusCircleIcon" />
                                      {{i18nT(`Add new answer`)}}
                                  </b-button>
                              </b-form-group>
                          </b-col>
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Select the number of possible answers`)"
                                  label-for="freeText"
                              >
                                  <div class="demo-inline-spacing">
                                      <b-form-radio
                                          v-model="questionPossibleAnswers"
                                          name="questionPossibleAnswers"
                                          value="1"
                                          class="mt-0"
                                      >
                                          {{i18nT(`Allow one answer`)}}
                                      </b-form-radio>
                                      <b-form-radio
                                          v-model="questionPossibleAnswers"
                                          name="questionPossibleAnswers"
                                          value="99"
                                          class="mt-0"
                                      >
                                          {{i18nT(`Allow multiple answers`)}}
                                      </b-form-radio>
                                  </div>
                              </b-form-group>
                          </b-col>
                          <b-col cols="12">
                              <b-form-group
                                  label-for="questionOtherOption"
                              >

                                  <b-form-checkbox
                                      name="questionOtherOption"
                                      id="questionOtherOption"
                                      switch
                                      :checked="questionOtherOption"
                                      v-model="questionOtherOption"
                                  >
                                      {{i18nT(`Include OTHER option and allow users to enter their own answer.`)}}
                                  </b-form-checkbox>
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <b-row v-if="questionType.value === '3'">
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Please select the number of grading steps for the rating scale. You can select between 2,3,5 and 10 steps.`)"
                                  label-for="freeText"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      name="questionSteps"
                                      rules="required"
                                  >
                                      <div>
                                          <b-form-checkbox-group
                                              v-model="questionSteps"
                                              :options="questionStepOptions"
                                              buttons
                                              button-variant="primary"
                                              size="md"
                                              name="questionSteps"
                                          />
                                      </div>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col cols="12">
                              <b-form-group
                                  :label="i18nT(`Grading label options`)"
                                  label-for="questionGrading"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      name="questionGrading"
                                      rules="required"
                                  >
                                      <b-form-radio
                                          v-if="questionSteps === 2"
                                          v-model="questionGrading"
                                          name="questionGrading"
                                          value="1"
                                          class="mb-50"
                                      >
                                          {{i18nT(`Emoji face`)}} 🙂 - 🙁
                                      </b-form-radio>
                                      <b-form-radio
                                          v-model="questionGrading"
                                          name="questionGrading"
                                          value="2"
                                          class="mb-50"
                                      >
                                          {{i18nT(`Numbers (Ex. 1 to 10)`)}}
                                      </b-form-radio>
                                      <b-form-radio
                                          v-model="questionGrading"
                                          name="questionGrading"
                                          value="3"
                                          class="mb-50"
                                      >
                                          {{i18nT(`Custom starting and ending label`)}}
                                      </b-form-radio>
                                      <b-form-radio
                                          v-model="questionGrading"
                                          name="questionGrading"
                                          value="4"
                                          class="mb-50"
                                      >
                                          {{i18nT(`Custom labels`)}}
                                      </b-form-radio>
                                      <div>
                                          <small class="text-danger">{{ errors[0] }}</small>
                                      </div>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <b-row v-if="questionGrading === '3'">
                          <b-col cols="6">
                              <b-form-group
                                  :label="i18nT(`First step label`)" class="required"
                                  label-for="questionFirstStep"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      name="first step label"
                                      rules="required"
                                  >
                                      <b-form-input
                                          v-model="questionFirstStep"
                                          :placeholder="i18nT(`E.g. Question Name`)"
                                          name="questionFirstStep"
                                          id="questionFirstStep"
                                          :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col cols="6">
                              <b-form-group
                                  :label="i18nT(`Last step label`)" class="required"
                                  label-for="questionLastStep"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      name="last step label"
                                      rules="required"
                                  >
                                      <b-form-input
                                          v-model="questionLastStep"
                                          :placeholder="i18nT(`E.g. Question Name`)"
                                          name="questionLastStep"
                                          id="questionLastStep"
                                          :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <b-row v-if="questionGrading === '4'">
                          <b-col cols="12">
                              <b-form-group
                                  v-for="i in questionSteps"
                                  :key="i"
                                  :label="i18nT(`'Custom label #'`) + i"
                                  label-for="questionStepStrings"
                              >
                                  <validation-provider
                                      #default="{ errors }"
                                      :name="'custom label #' + i"
                                      rules="required"
                                  >
                                      <b-form-input
                                          v-model="questionStepStrings[i - 1]"
                                          :placeholder="i18nT(`E.g. Question Name`)"
                                          :name="'questionStepStrings' + i"
                                          :id="'questionStepStrings' + i"
                                          :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                      </b-row>
                  </b-form>
              </validation-observer>
          </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FolderIcon" /> {{i18nT(`Existing questions`)}}
          </template>
          <validation-observer ref="existingQuestion">
            <b-row>
              <b-col sm="12">
                <b-form-group
                  :label="i18nT(`Select question`)" class="required"
                  label-for="existingQuestion"
                >
                  <v-select
                    v-model="selectedQuestion"
                    :options="existQuestions"
                    label="text"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-tab>

      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
  BCardBody,
  // BFormSelect,
  VBModal,
  BTabs,
  BTab,
  // BInputGroup,
  // BInputGroupText,
  BFormRadio,
  BListGroupItem,
  BCardHeader,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import draggable from 'vuedraggable'
import DatePicker from '@core/components/DatePicker.vue'
import {quillEditor} from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BButtonGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    vSelect,
    BFormTextarea,
    // BFormSelect,
    BAlert,
    BBadge,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    // BInputGroup,
    // BInputGroupText,
    BFormRadio,
    BListGroupItem,
    draggable,
    BCardHeader,
    BDropdown,
    BDropdownItem,
    DatePicker,
    quillEditor
  },
  directives: {
    Ripple,
    'b-modal': VBModal
  },
  data() {
    return {
      required,
      email,
      tabIndex: 0,
      survey: {},
      surveyTitle: '',
      surveyType: {value:'-1',text: 'Select type'},
      audienceGroup: { value: '' },
      surveyDescription: '',
      surveyIsTemplate: false,
      surveyQuestions: [],
      surveyScheduling: false,
      surveyRepeatFor: { value: '1' },
      surveyScheduleDate: '',
      surveyScheduleWeekly: [],
      surveyScheduleMonthly: '',
      surveyScheduleDayOfMonth: { value: ''},
      repeatOptions: [],
      scheduleWeeklyOptions: [],
      scheduleMonthlyOptions: [],
      scheduleDaysOfMonthOptions: [],
      surveyTypes: [],
      audienceGroups: [],
      existQuestions: [],
      selectedQuestion: null,
      questionTypes: [],
      questionGradings: [
        { value: 1, text: 'Simple face🙂 - 🙁' },
        { value: 2, text: 'Numbers (Ex. 1 to 10)' },
        { value: 3, text: 'Custom starting and ending label' },
        { value: 4, text: 'Custom labels' }
      ],
      questionStepOptions: [{text:2,value:2}, {text:3, value:3}, {text:5, value:5}, {text:10, value:10}],
      questionAnswersData: [{value:-1,text:''}],
      questionType: {value:'-1', text: 'Select type'},
      questionIndex: null,
      questionLabel: '',
      questionDescription: '',
      questionLinesCnt: 1,
      questionPossibleAnswers: 1,
      questionOtherOption: false,
      questionSteps: 0,
      questionStepStrings: [''],
      questionFirstStep: '',
      questionLastStep: '',
      questionGrading: 0,
        quillOption: {
            theme: 'snow',
            placeholder: '',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'link'],
                    [{list: 'ordered'}, {list: 'bullet'}],
                    ['clean']
                ]
            }
        }
    }
  },
  async created() {
    await Promise.all([
      this.getSurveyTypes(),
      this.getAudienceGroups(),
      this.getQuestions(),
      this.getScheduleOptions(),
      this.getScheduleWeeklyOptions(),
      this.getScheduleMonthlyOptions(),
      this.getScheduleDaysOfMonthOptions()
    ])

    this.$http.get(`surveys/questionTypes`).then(({ data }) => {
      const questionTypes = data.data
      for (const property in questionTypes) {
        if (property === '1')
          this.questionType = {
            value: property,
            text: questionTypes[property]
          }
        this.questionTypes.push({
          value: property,
          text: questionTypes[property]
        })
      }
    })

    const id = router.currentRoute.params.id
    if (id !== 'new') {
      this.$http.get(`surveys?id=${id}`).then(({ data }) => {
        this.survey = data.data.survey
        let survey = data.data.survey
        this.surveyTitle = survey.Label
        this.surveyType = this.surveyTypes.find(
          v => v.value === survey.Type + ''
        )
        this.audienceGroup = this.audienceGroups.find(
          v => v.value === survey.Audience + ''
        ) || { value: '', text: 'All employees' }
        this.surveyDescription = survey.Description
        this.surveyIsTemplate = survey.Template ? true : false
        this.surveyScheduling = survey.Repeating ? true : false
        this.surveyRepeatFor = this.repeatOptions.find(
          s => s.value == survey.RepeatFor
        )

        this.surveyScheduleDayOfMonth = this.scheduleDaysOfMonthOptions.find(e => parseInt(e.value) === survey.DayOfMonth)

        if (this.surveyRepeatFor && this.surveyRepeatFor.value === '2') {

            const repeatDays = (this.survey.RepeatData + '' || '').split(',')
            repeatDays.forEach(repeatDay => {
                this.surveyScheduleWeekly.push(
                    this.scheduleWeeklyOptions[parseInt(repeatDay) - 1]
                )
            })
        }

        if (this.surveyRepeatFor && this.surveyRepeatFor.value === '3') {
          this.surveyScheduleMonthly = this.scheduleMonthlyOptions.filter(s =>
            survey.RepeatData.split(',').includes(s.value)
          )
        }
        this.surveyScheduleDate = survey.RepeatDate
        this.surveyQuestions = survey.Questions
      })
    }
  },
  computed: {
    hasRouteId() {
      return router && router.currentRoute.params.id
    },
      notSent() {
          return this.survey.SentAt === null
      }
  },
  methods: {
    async getScheduleOptions() {
      const { data } = await this.$http.get(`surveys/repeatOptions`)
      const scheduleOptions = data.data
      for (const property in scheduleOptions) {
        if (property === '1')
          this.surveyRepeatFor = {
            value: property,
            text: scheduleOptions[property]
          }
        this.repeatOptions.push({
          value: property,
          text: scheduleOptions[property]
        })
      }
    },
    async getScheduleWeeklyOptions() {
      const { data } = await this.$http.get(`surveys/repeatWeekly`)
      const scheduleWeeklyOptions = data.data
      for (const property in scheduleWeeklyOptions) {
        this.scheduleWeeklyOptions.push({
          value: property,
          text: scheduleWeeklyOptions[property]
        })
      }
    },
    async getScheduleMonthlyOptions() {
      const { data } = await this.$http.get(`surveys/repeatMonthly`)
      const scheduleMonthlyOptions = data.data
      for (const property in scheduleMonthlyOptions) {
        this.scheduleMonthlyOptions.push({
          value: property,
          text: scheduleMonthlyOptions[property]
        })
      }
        let everyMonth = {...this.scheduleMonthlyOptions[12]};
        this.scheduleMonthlyOptions.splice(12, 1);
        this.scheduleMonthlyOptions.unshift(everyMonth);

    },
    async getScheduleDaysOfMonthOptions() {
      const { data } = await this.$http.get(`surveys/daysOfMonth`)
      const scheduleDaysOfMonthOptions = data.data
      for (const property in scheduleDaysOfMonthOptions) {
        this.scheduleDaysOfMonthOptions.push({
          value: property,
          text: scheduleDaysOfMonthOptions[property]
        })
      }
    },
    async getAudienceGroups() {
      const { data } = await this.$http.get(`surveys/audienceGroups`)
      const audienceGroups = data.data
      for (const property in audienceGroups) {
        this.audienceGroups.push({
          value: property,
          text: audienceGroups[property]
        })
      }
      this.audienceGroups.unshift({
        value: '',
        text: 'All employees'
      })
      this.audienceGroup = this.audienceGroups[0];

    },
    async getSurveyTypes() {
      const { data } = await this.$http.get(`surveys/surveyTypes`)
      const surveyTypes = data.data
      for (const property in surveyTypes) {
        this.surveyTypes.push({
          value: property,
          text: surveyTypes[property]
        })
      }
    },
    async getQuestions() {
      const { data } = await this.$http.get(
        `surveys/questions?show_per_page=1000`
      )
      this.existQuestions = data.data.questions.map(q => ({
        value: q.Id,
        text: q.Label,
        ...q
      }))
    },
    getNameByQuestionTypeId(typeId) {
      const questionType = this.questionTypes.find(type => type.value == typeId)
      return questionType ? questionType.text : ''
    },
    getNameByQuestionGradingId(questionGradingId) {
      const questionGrading = this.questionGradings.find(
        questionGrading => questionGrading.value == questionGradingId
      )
      return questionGrading ? questionGrading.text : ''
    },
    editSurveyQuestionByIdx(index) {
      const surveyQuestion = this.surveyQuestions[index]

      this.questionIndex = index;

      this.questionLabel = surveyQuestion.Label;
      this.questionType = this.questionTypes.find(
            type => type.value == surveyQuestion.Type
      )

        this.questionDescription = surveyQuestion.Description;
        this.questionLinesCnt = surveyQuestion.LinesCnt;
        this.questionOtherOption = surveyQuestion.OtherOption;
        this.questionSteps = surveyQuestion.Steps;
        const stepStrings = Object.keys(surveyQuestion)
                            .filter(key => key.startsWith('Step') && key!=='Steps')
                            .map(key => surveyQuestion[key]);
        this.questionStepStrings = stepStrings;
        this.questionFirstStep = surveyQuestion.FirstStep;
        this.questionLastStep = surveyQuestion.LastStep;
        this.questionGrading = surveyQuestion.Grading;
        this.questionAnswersData = surveyQuestion.AnswersData.map(answer=> ({text:answer.Label, value:answer.Id}));
        this.selectedQuestion = surveyQuestion;
        this.tabIndex = 0;
        this.$bvModal.show('modal-question')
    },
    removeSurveyQuestionByIdx(index) {
      this.surveyQuestions.splice(index, 1)
    },
    clearQuestionData() {
      this.selectedQuestion = null
      this.tabIndex = 0
      this.questionType = {
        value: '1',
        text: this.questionTypes.find(q => q.value === '1').text
      }
      this.questionLabel = ''
      this.questionDescription = ''
      this.questionLinesCnt = 1
      this.questionOtherOption = false
      this.questionSteps = 0
      this.questionStepStrings = []

      this.questionFirstStep = ''
      this.questionLastStep = ''
      this.questionGrading = 0
      this.questionAnswersData = [{value:-1,text:''}]
    },
    onAddNewQuestionSubmit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      if (this.tabIndex === 1) {
        this.$refs.existingQuestion
          .validate()
          .then(success => {
            if (success) {
              this.surveyQuestions.push(this.selectedQuestion)
              this.selectedQuestion = null
              this.$bvModal.hide('modal-question')
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else if (this.tabIndex === 0) {
        this.$refs.newQuestion
          .validate()
          .then(success => {
            if (!success) return
            // eslint-disable-next-line

            const formData = new FormData()
            formData.append('Label', this.questionLabel)
            formData.append('Type', parseInt(this.questionType.value))
            formData.append('Description', this.questionDescription)
            if (this.questionType.value === '1') {
              formData.append('LinesCnt', parseInt(this.questionLinesCnt))
            } else if (this.questionType.value === '2') {
              formData.append(
                'PossibleAnswers',
                parseInt(this.questionPossibleAnswers)
              )
              formData.append('OtherOption', this.questionOtherOption ? 1 : 0)
              this.questionAnswersData.forEach(d =>
              {
                formData.append('AnswerData[]', d.text)}
              )
            } else {
              formData.append('Steps', parseInt(this.questionSteps))
              formData.append('Grading', parseInt(this.questionGrading))
              formData.append('FirstStep', this.questionFirstStep)
              formData.append('LastStep', this.questionLastStep)

              for (let i = 0; i < 10; i++) {
                formData.append('Step' + (i + 1), this.questionStepStrings[i])
              }
            }

            this.$http
              .post('surveys/questions', formData)
              .then(response => {
                const newQuestion = response.data.data
                if(this.questionIndex !== null){
                  this.surveyQuestions.splice(this.questionIndex,1);
                  this.surveyQuestions.splice(this.questionIndex, 0, newQuestion)
                  this.questionIndex=null;
                }else{
                  this.surveyQuestions.push(newQuestion);
                }

                console.log('surveyQuestions', this.surveyQuestions)
                this.$bvModal.hide('modal-question')
                this.getQuestions()

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.successMessage(response),
                    icon: 'InfoIcon',
                    variant: 'success'
                  }
                })
              })
              .catch(err => {
                console.log(err)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.errorMessage(err),
                    icon: 'InfoIcon',
                    variant: 'danger'
                  }
                })
              })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    onAddNewQuestionClick() {
      this.$bvModal.show('modal-question')
    },
    onAddNewAnswerClick() {
      this.questionAnswersData.push({value:-1,text:''})
    },
    onDeleteAnswerClick(idx) {
      this.questionAnswersData.splice(idx, 1)
    },
    updateAnswerOrder() {},
    onAddNewSurveySubmit(andSend = false) {
      const id = router.currentRoute.params.id
        let self = this;
      this.$refs.surveyRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const formData = new FormData()
          if (id !== 'new') formData.append('id', id)

          formData.append('Label', this.surveyTitle)
          formData.append('Type', this.surveyType.value)
          formData.append('Description', this.surveyDescription)
          formData.append('Audience', this.audienceGroup.value)
          formData.append('template', this.surveyIsTemplate ? 1 : 0)
          formData.append(
            'survey_questions',
            this.surveyQuestions.map(q => q.Id).join()
          )

          if (this.surveyRepeatFor && this.surveyRepeatFor.value) {
            formData.append('repeating', this.surveyScheduling ? 1 : 0)
            formData.append('repeat_for', this.surveyRepeatFor.value)
            if (
              this.surveyRepeatFor.value === '1' ||
              this.surveyRepeatFor.value === '4'
            )
              formData.append('repeat_date', this.surveyScheduleDate)
            else if (this.surveyRepeatFor.value === '2')
              formData.append(
                'repeat_weekly',
                this.surveyScheduleWeekly.map(workday => workday.value).join(',')
              )
            else if (this.surveyRepeatFor.value === '3') {
              formData.append(
                'repeat_monthly',
                this.surveyScheduleMonthly.map(m => m.value).join()
              )
              formData.append(
                'day_of_month',
                this.surveyScheduleDayOfMonth.value
              )
            }
          }

          this.$http
            .post('surveys', formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })
              console.log("Resp on save: ", res);
              if(andSend) {
                  self.$http
                      .post(`surveys/send?survey_id=${res.data.data.Id}`)
                      .then(response => {
                          self.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                  title: this.successMessage(response),
                                  icon: 'InfoIcon',
                                  variant: 'success'
                              }
                          })
                          this.$router.push({name: 'em-surveys'})
                      })
                      .catch(err => {
                          this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                  title: this.errorMessage(err),
                                  icon: 'InfoIcon',
                                  variant: 'danger'
                              }
                          })
                      })
              } else {
                  this.$router.push({name: 'em-surveys'})
              }

            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'InfoIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    },
    onSave() {
      this.onAddNewSurveySubmit(false)
    },
    onDelete() {
      this.$swal({
        title: this.i18nT(`Are you sure you want to delete this survey?`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18nT(`Confirm`),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          const id = router.currentRoute.params.id
          this.$http
            .delete(`surveys?id=${id}`)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })

              router.push({name: 'surveys'})
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'InfoIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    }
  },
  watch: {
    questionType:{
      deep:true,
      handler: function(val){
        if(val.value !== "3"){
          this.questionGrading = 0;
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.section-title {
  font-size: 11px;
}
.document-name {
  display: inline;
  line-height: 32px;
}
.document-actions-bar {
  float: right;
}
</style>
